import axios from 'axios';
const service = axios.create({
    // process.env.NODE_ENV === 'development'
    // easy-mock服务挂了，暂时不使用了
    baseURL: '',
    withCredentials:false,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        token:localStorage.getItem("token")
      },
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
