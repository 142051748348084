import request from '../utils/request';
// import { loginreq} from './axiosFun';
// var hotelUrl = "https://dz53.aulod.com/api"  //线上访问路径
// var hotelUrl = "http://www.ht.com/api"  //本地访问路径
var hotelUrl = "/api"
// const Token = localStorage.getItem("token"),hader:{token:Token}

const uploadImgurl = hotelUrl + 'common/public/upload_one_image' // 图片上传

const login = hotelUrl + '/common/public/admin_login' // 登录
const post_hotel = hotelUrl + '/hotel/hotel/post_hotel_data' // 添加酒店
const get_infoData = hotelUrl + '/hotel/hotel/get_info' //获取酒店信息


const get_room_url = hotelUrl + '/hotel/hotel/get_room_info' //获取客房信息
const post_room_dataurl = hotelUrl + '/hotel/hotel/post_room_data' //添加客房
const get_room_price_url = hotelUrl + '/hotel/hotel/get_room_price_info' //月份价格
const post_room_price_url = hotelUrl + '/hotel/hotel/post_room_price' //添加月份价格
const get_room_act_price_url = hotelUrl + '/hotel/hotel/get_room_act_price_info' //活动价格
const post_room_act_url = hotelUrl + '/hotel/hotel/post_room_act_price' //添加活动价格


const add_postmunu = hotelUrl + '/common/menu/add_menu' //添加菜单信息
const menu_listmunu = hotelUrl + '/common/menu/menu_list' //菜单列表
const delete_munu = hotelUrl + '/common/menu/delete_menu' //菜单删除
const edit_munu = hotelUrl + '/common/menu/edit_menu' //菜单编辑
const get_first_menu = hotelUrl + '/common/menu/get_first_menu' //菜单编辑取值上级页面信息

const role_list = hotelUrl + '/common/rbac/role_list' //角色列表
const role_add_post = hotelUrl + '/common/rbac/add_role' //添加角色
const delete_role = hotelUrl + '/common/rbac/delete_role' //删除角色
const edit_role = hotelUrl + '/common/rbac/edit_role' //编辑角色
const get_authorize_info = hotelUrl + '/common/rbac/get_authorize_info' //角色授权表单获取授权的信息
const add_authorize_url = hotelUrl + '/common/rbac/add_authorize' //添加授权信息


const add_user = hotelUrl + '/common/user/add_user' //添加管理员
const get_role_list = hotelUrl + '/common/user/get_role_list' //管理员角色
const user_list = hotelUrl + '/common/user/user_list' //管理员列表
const get_user_info = hotelUrl + '/common/user/get_user_info' //管理员编辑
const delete_user = hotelUrl + '/common/user/delete_user' //管理员删除
const post_facility = hotelUrl + '/hotel/hotel/post_facility_data' //管理员删除

const post_brand = hotelUrl + '/hotel/hotel/post_brand_data' //管理员


const get_meeting_url = hotelUrl + '/hotel/meeting/get_meeting_info' //获取会议信息接口
const post_meeting_url = hotelUrl + '/hotel/meeting/post_meeting_data' //添加会议信息接口



const get_ship_url = hotelUrl + '/hotel/ship/get_ship_info' //获取邮轮信息接口
const post_ship_url = hotelUrl + '/hotel/ship/post_ship_data' //添加邮轮信息接口


const get_trip_url = hotelUrl + '/hotel/trip/get_trip_info' //获取旅游信息接口
const post_trip_url = hotelUrl + '/hotel/trip/post_trip_data' //获取旅游信息接口


const category_url = hotelUrl + '/common/article/category_list' //文章分类列表









/**
 * 登录信息
 */
export const Login = loginquery => { return request({url: login ,method: 'post',params: loginquery})}; 
export const uploadImgl_ist = uploadImgdata => { return request({url: uploadImgurl ,method: 'post',params: uploadImgdata})}; 
/**
 * 普通表格
 */
export const fetchData = query => {return request({url: './table.json',method: 'get',params: query})}; //表格

/**
 * 酒店管理
 */
export const post_hotel_data = post_hotel_data => {return request({url:post_hotel,method: 'post',params: post_hotel_data})};
export const get_info = get_info => {return request({url: get_infoData, method: 'post',params: get_info});};
/**
 * 客房管理
 */
export const get_room_list = get_room_data => {return request({url: get_room_url, method: 'post',params: get_room_data});};
export const post_room_list = post_room_data => {return request({url: post_room_dataurl, method: 'post',params: post_room_data});};
export const get_room_price_list = get_room_price_data => {return request({url: get_room_price_url, method: 'post',params: get_room_price_data});};
export const post_room_price_list = post_room_price_data => {return request({url: post_room_price_url, method: 'post',params: post_room_price_data});};
export const get_room_act_price_list = get_room_act_price_data => {return request({url: get_room_act_price_url, method: 'post',params: get_room_act_price_data});};
export const post_room_act_list = post_room_act_data => {return request({url: post_room_act_url, method: 'post',params: post_room_act_data});};

/**
 * 系统管理
 */
export const add_post = add_postmuau => {return request({url: add_postmunu, method: 'post',params: add_postmuau});};
export const menu_list = menu_listmuau => {return request({url: menu_listmunu, method: 'get',params: menu_listmuau});};
export const delete_list = delete_muau => {return request({url: delete_munu, method: 'post',params: delete_muau});};
export const first_menulist = first_menudata => {return request({url: get_first_menu, method: 'post',params: first_menudata});};
export const edit_list = edit_muau => {return request({url: edit_munu, method: 'post',params: edit_muau})};
export const role_getlist = role_muau => {return request({url: role_list, method: 'post',params: role_muau});};
export const role_add = role_addData => {return request({url: role_add_post, method: 'post',params: role_addData});};
export const delete_data = delete_roledata => {return request({url: delete_role, method: 'post',params: delete_roledata});};
export const edit_rdata = edit_roledata => {return request({url: edit_role, method: 'post',params: edit_roledata});};
export const add_userdata = userdata => {return request({url: add_user, method: 'post',params: userdata});};
export const role_listdata = get_role_listdata => {return request({url: get_role_list, method: 'post',params: get_role_listdata});};
export const user_listdata = getuser_listdata => {return request({url: user_list, method: 'post',params: getuser_listdata});};
export const delete_userlist = delete_userdata => {return request({url: delete_user, method: 'post',params: delete_userdata});};
export const get_user_infolist = get_user_infodata => {return request({url: get_user_info, method: 'post',params: get_user_infodata});};
export const post_facilitylist = post_facilitydata => {return request({url: post_facility, method: 'post',params: post_facilitydata});};
export const authorize_infolist = authorize_infodata => {return request({url: get_authorize_info, method: 'post',params: authorize_infodata});};
export const add_authorize_list = add_authorize_data => {return request({url: add_authorize_url, method: 'post',params: add_authorize_data});};


/**
 * 品牌管理
 */
 export const post_brandlist = post_branddata => {return request({url: post_brand, method: 'post',params: post_branddata});};



 /**
  * 会议管理
  */
 export const get_meeting_list = get_meeting_data => {return request({url: get_meeting_url, method: 'post',params: get_meeting_data});};
 export const post_meeting_list = post_meeting_data => {return request({url: post_meeting_url, method: 'post',params: post_meeting_data});};

 /**
  * 邮轮管理
  */
 export const get_ship_list = get_ship_data => {return request({url: get_ship_url, method: 'post',params: get_ship_data});};
 export const post_ship_list = post_ship_data => {return request({url: post_ship_url, method: 'post',params: post_ship_data});};
 
 /**
  * 旅游管理
  */
 export const get_trip_list = get_trip_data => {return request({url: get_trip_url, method: 'post',params: get_trip_data});};
 export const post_trip_list = post_trip_data => {return request({url: post_trip_url, method: 'post',params: post_trip_data});};

 /**
  * 文章分类管理
  */
 export const category_list = category_data => {return request({url: category_url, method: 'post',params: category_data});};
